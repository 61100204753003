<template>
 <LayoutMain/>
</template>

<script>
import  LayoutMain from '@/components/LayoutMain.vue'

export default {
  name: 'App',
  components: {
  LayoutMain
  }
}
</script>

<style>
	 @import url(https://fonts.googleapis.com/css?family=Nunito:regular,500,600,700,800,900);
	 body {
		font-family: 'Nunito', Arial;
	 }
</style>
