<template>
	<div>
		<div class="mind-panel">
			<div class="mind-panel__column">
				   <label class="mind-panel__label" for="columns">Колонки:</label>
					<div class="mind-panel__block flex">
						<button class="mind-panel__button-small button" @click="decrementColumns">-</button>
						<input class="mind-panel__input" type="text" id="columns" v-model.number="columns">
						<button class="mind-panel__button-small button" @click="incrementColumns">+</button>
			      </div>
	      </div>
	      <div class="mind-panel__column">
		   	 <label class="mind-panel__label" for="rows">Ряды:</label>
				<div class="mind-panel__block flex">
					<button class="mind-panel__button-small button" @click="decrementRows">-</button>
					<input class="mind-panel__input" type="text" id="rows" v-model.number="rows">
					<button class="mind-panel__button-small button" @click="incrementRows">+</button>
				</div>
	      </div>
	 		 <button class="mind-panel__button button" @click="showGrid">Перемешать</button>
		</div>

	  <div class="mind-images__items grid" :style="gridStyle">
		 <div class="mind-images__item item-mind-images" v-for="(image, index) in displayedImages" :key="index">
			<div class="item-mind-images__body">
			  <div class="item-mind-images__image ibg">
				 <img :src="image.src" :alt="image.alt">
			  </div>
			</div>
		 </div>
	  </div>
	</div>
</template>


<script>
export default {
  data() {
    return {
		columns: 0,
      rows: 0,
		images: [
  { src: require('@/assets/images/1.jpg'), alt: 'my-mind-image1' },
  { src: require('@/assets/images/2.jpg'), alt: 'my-mind-image2' },
  { src: require('@/assets/images/3.jpg'), alt: 'my-mind-image3' },
  { src: require('@/assets/images/4.jpg'), alt: 'my-mind-image4' },
  { src: require('@/assets/images/5.jpg'), alt: 'my-mind-image5' },
  { src: require('@/assets/images/6.jpg'), alt: 'my-mind-image6' },
  { src: require('@/assets/images/7.jpg'), alt: 'my-mind-image7' },
  { src: require('@/assets/images/8.jpg'), alt: 'my-mind-image8' },
  { src: require('@/assets/images/9.jpg'), alt: 'my-mind-image9' },
  { src: require('@/assets/images/10.jpg'), alt: 'my-mind-image10' },
  { src: require('@/assets/images/11.jpg'), alt: 'my-mind-image11' },
  { src: require('@/assets/images/12.jpg'), alt: 'my-mind-image12' },
  { src: require('@/assets/images/13.jpg'), alt: 'my-mind-image13' },
  { src: require('@/assets/images/14.jpg'), alt: 'my-mind-image14' },
  { src: require('@/assets/images/15.jpg'), alt: 'my-mind-image15' },
  { src: require('@/assets/images/16.jpg'), alt: 'my-mind-image16' },
  { src: require('@/assets/images/17.jpg'), alt: 'my-mind-image17' },
  { src: require('@/assets/images/18.jpg'), alt: 'my-mind-image18' },
  { src: require('@/assets/images/19.jpg'), alt: 'my-mind-image19' },
  { src: require('@/assets/images/20.jpg'), alt: 'my-mind-image20' },
  { src: require('@/assets/images/21.jpg'), alt: 'my-mind-image21' },
  { src: require('@/assets/images/22.jpg'), alt: 'my-mind-image22' },
  { src: require('@/assets/images/23.jpg'), alt: 'my-mind-image23' },
  { src: require('@/assets/images/24.jpg'), alt: 'my-mind-image24' },
  { src: require('@/assets/images/25.jpg'), alt: 'my-mind-image25' },
  { src: require('@/assets/images/26.jpg'), alt: 'my-mind-image26' },
  { src: require('@/assets/images/27.jpg'), alt: 'my-mind-image27' },
  { src: require('@/assets/images/28.jpg'), alt: 'my-mind-image28' },
  { src: require('@/assets/images/29.jpg'), alt: 'my-mind-image29' },
  { src: require('@/assets/images/30.jpg'), alt: 'my-mind-image30' },
  { src: require('@/assets/images/31.jpg'), alt: 'my-mind-image31' },
  { src: require('@/assets/images/32.jpg'), alt: 'my-mind-image32' },
  { src: require('@/assets/images/33.jpg'), alt: 'my-mind-image33' },
  { src: require('@/assets/images/34.jpg'), alt: 'my-mind-image34' },
  { src: require('@/assets/images/35.jpg'), alt: 'my-mind-image35' },
  { src: require('@/assets/images/36.jpg'), alt: 'my-mind-image36' },
  { src: require('@/assets/images/37.jpg'), alt: 'my-mind-image37' },
  { src: require('@/assets/images/38.jpg'), alt: 'my-mind-image38' },
  { src: require('@/assets/images/39.jpg'), alt: 'my-mind-image39' },
  { src: require('@/assets/images/40.jpg'), alt: 'my-mind-image40' },
  { src: require('@/assets/images/41.jpg'), alt: 'my-mind-image41' },
  { src: require('@/assets/images/42.jpg'), alt: 'my-mind-image42' },
  { src: require('@/assets/images/43.jpg'), alt: 'my-mind-image43' },
  { src: require('@/assets/images/44.jpg'), alt: 'my-mind-image44' },
  { src: require('@/assets/images/45.jpg'), alt: 'my-mind-image45' },
  { src: require('@/assets/images/46.jpg'), alt: 'my-mind-image46' },
  { src: require('@/assets/images/47.jpg'), alt: 'my-mind-image47' },
  { src: require('@/assets/images/48.jpg'), alt: 'my-mind-image48' },
  { src: require('@/assets/images/49.jpg'), alt: 'my-mind-image49' },
  { src: require('@/assets/images/50.jpg'), alt: 'my-mind-image50' },
  { src: require('@/assets/images/51.jpg'), alt: 'my-mind-image51' },
  { src: require('@/assets/images/52.jpg'), alt: 'my-mind-image52' },
  { src: require('@/assets/images/53.jpg'), alt: 'my-mind-image53' },
  { src: require('@/assets/images/54.jpg'), alt: 'my-mind-image54' },
  { src: require('@/assets/images/55.jpg'), alt: 'my-mind-image55' },
  { src: require('@/assets/images/56.jpg'), alt: 'my-mind-image56' },
  { src: require('@/assets/images/57.jpg'), alt: 'my-mind-image57' },
  { src: require('@/assets/images/58.jpg'), alt: 'my-mind-image58' },
  { src: require('@/assets/images/59.jpg'), alt: 'my-mind-image59' },
  { src: require('@/assets/images/60.jpg'), alt: 'my-mind-image60' },
  { src: require('@/assets/images/61.jpg'), alt: 'my-mind-image61' },
  { src: require('@/assets/images/62.jpg'), alt: 'my-mind-image62' },
  { src: require('@/assets/images/63.jpg'), alt: 'my-mind-image63' },
  { src: require('@/assets/images/64.jpg'), alt: 'my-mind-image64' },
  { src: require('@/assets/images/65.jpg'), alt: 'my-mind-image65' },
  { src: require('@/assets/images/66.jpg'), alt: 'my-mind-image66' },
  { src: require('@/assets/images/67.jpg'), alt: 'my-mind-image67' },
  { src: require('@/assets/images/68.jpg'), alt: 'my-mind-image68' },
  { src: require('@/assets/images/69.jpg'), alt: 'my-mind-image69' },
  { src: require('@/assets/images/70.jpg'), alt: 'my-mind-image70' },
  { src: require('@/assets/images/71.jpg'), alt: 'my-mind-image71' },
  { src: require('@/assets/images/72.jpg'), alt: 'my-mind-image72' },
  { src: require('@/assets/images/73.jpg'), alt: 'my-mind-image73' },
  { src: require('@/assets/images/74.jpg'), alt: 'my-mind-image74' },
  { src: require('@/assets/images/75.jpg'), alt: 'my-mind-image75' },
  { src: require('@/assets/images/76.jpg'), alt: 'my-mind-image76' },
{ src: require('@/assets/images/77.jpg'), alt: 'my-mind-image77' },
{ src: require('@/assets/images/78.jpg'), alt: 'my-mind-image78' },
{ src: require('@/assets/images/79.jpg'), alt: 'my-mind-image79' },
{ src: require('@/assets/images/80.jpg'), alt: 'my-mind-image80' },
{ src: require('@/assets/images/81.jpg'), alt: 'my-mind-image81' },
{ src: require('@/assets/images/82.jpg'), alt: 'my-mind-image82' },
{ src: require('@/assets/images/83.jpg'), alt: 'my-mind-image83' },
{ src: require('@/assets/images/84.jpg'), alt: 'my-mind-image84' },
{ src: require('@/assets/images/85.jpg'), alt: 'my-mind-image85' },
{ src: require('@/assets/images/86.jpg'), alt: 'my-mind-image86' },
{ src: require('@/assets/images/87.jpg'), alt: 'my-mind-image87' },
{ src: require('@/assets/images/88.jpg'), alt: 'my-mind-image88' },
{ src: require('@/assets/images/89.jpg'), alt: 'my-mind-image89' },
{ src: require('@/assets/images/90.jpg'), alt: 'my-mind-image90' },
{ src: require('@/assets/images/91.jpg'), alt: 'my-mind-image91' },
{ src: require('@/assets/images/92.jpg'), alt: 'my-mind-image92' },
{ src: require('@/assets/images/93.jpg'), alt: 'my-mind-image93' },
{ src: require('@/assets/images/94.jpg'), alt: 'my-mind-image94' },
{ src: require('@/assets/images/95.jpg'), alt: 'my-mind-image95' },
{ src: require('@/assets/images/96.jpg'), alt: 'my-mind-image96' },
{ src: require('@/assets/images/97.jpg'), alt: 'my-mind-image97' },
{ src: require('@/assets/images/98.jpg'), alt: 'my-mind-image98' },
{ src: require('@/assets/images/99.jpg'), alt: 'my-mind-image99' },
{ src: require('@/assets/images/100.jpg'), alt: 'my-mind-image100' },
{ src: require('@/assets/images/101.jpg'), alt: 'my-mind-image101' },
{ src: require('@/assets/images/102.jpg'), alt: 'my-mind-image102' },
{ src: require('@/assets/images/103.jpg'), alt: 'my-mind-image103' },
{ src: require('@/assets/images/104.jpg'), alt: 'my-mind-image104' },
{ src: require('@/assets/images/105.jpg'), alt: 'my-mind-image105' },
{ src: require('@/assets/images/106.jpg'), alt: 'my-mind-image106' },
{ src: require('@/assets/images/107.jpg'), alt: 'my-mind-image107' },
{ src: require('@/assets/images/108.jpg'), alt: 'my-mind-image108' },
{ src: require('@/assets/images/109.jpg'), alt: 'my-mind-image109' },
{ src: require('@/assets/images/110.jpg'), alt: 'my-mind-image110' },
{ src: require('@/assets/images/111.jpg'), alt: 'my-mind-image111' },
{ src: require('@/assets/images/112.jpg'), alt: 'my-mind-image112' },
{ src: require('@/assets/images/113.jpg'), alt: 'my-mind-image113' },
{ src: require('@/assets/images/114.jpg'), alt: 'my-mind-image114' },
{ src: require('@/assets/images/115.jpg'), alt: 'my-mind-image115' },
{ src: require('@/assets/images/116.jpg'), alt: 'my-mind-image116' },
{ src: require('@/assets/images/117.jpg'), alt: 'my-mind-image117' },
{ src: require('@/assets/images/118.jpg'), alt: 'my-mind-image118' },
{ src: require('@/assets/images/119.jpg'), alt: 'my-mind-image119' },
{ src: require('@/assets/images/120.jpg'), alt: 'my-mind-image120' },
{ src: require('@/assets/images/121.jpg'), alt: 'my-mind-image121' },


],
    };
  },
  computed: {
    displayedImages() {
      return this.images.slice(0, this.columns * this.rows);
    },
    gridStyle() {
      return {
        gridTemplateColumns: `repeat(${this.columns}, minmax(auto, 100px))`,
        gridTemplateRows: `repeat(${this.rows}, minmax(auto, 100px))`,
      };
    },
  },
  methods: {
	showGrid() {
	// Перемешать изображения
	this.shuffleImages();

	// Обновить отображение сетки при изменении количества колонок и рядов
	const totalImages = this.columns * this.rows;
	this.displayedImages = this.images.slice(0, totalImages);
	},
	shuffleImages() {
	this.images.sort(() => Math.random() - 0.5);
	},
    incrementColumns() {
      this.columns++;
    },
    decrementColumns() {
      if (this.columns > 0) {
        this.columns--;
      }
    },
    incrementRows() {
      this.rows++;
    },
    decrementRows() {
      if (this.rows > 0) {
        this.rows--;
      }
    },
  },
};
</script>


<style>
input, button, textarea {
    font-family: "Nunito", Arial;
    font-size: inherit;
}

.grid {
	padding: 20px;
	display: grid;
	/* minmax(100px, auto) */
	grid-template-columns: repeat(1, minmax(auto, 100px));
   grid-template-rows: repeat(1, minmax(auto, 100px)); 
	background-color: #ffffff;
	box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.2);
   border-radius: 26px;
	justify-content: center;
	align-items: center;
}

.flex{
	display: flex;
}
.button {
	background: #ed9512;
    border-radius: 30px;
    border: none;
    font-weight: 700;
    font-size: 14px;
    color: #fff;
    white-space: nowrap;
    height: 40px;
    text-transform: uppercase;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0 30px;
    transition: all 0.3s ease 0s;
    cursor: pointer;
}

.mind-images {
  margin-bottom: 20px;
}
   .mind-images__container {
  }
 .mind-images__items {
    gap: 2px;
  }
   .mind-images__item {
  }


.item-mind-images__body {
	border: 3px solid #30547F; 
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    overflow: hidden;
	 /* max-width: 120px;
	 max-height: 120px; */
}
.item-mind-images__image {
    width: 100%;
    padding-bottom:100%;
	 background-color: #fff;
}
.item-mind-images__image img {
      transition: all 1s ease 0s;
      object-fit: cover;
      object-position: top;
    }
  
.item-mind-images__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    z-index: 5;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #251d53dd;
    transition: all 1s ease 0s;
    padding: 20px;
    transform: translate(0px, 130%);
  }

	.ibg {
    position: relative;
}
.ibg img{
	width:100%;
	position: absolute;
	object-fit:contain;
}

.mind-panel {
	display: flex;
	align-items: center;
	padding: 10px;
	margin: 20px 0 30px 0;
	background-color: #ffffff;
	box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.2);
   border-radius: 26px;
	justify-content: center;
	flex-wrap: wrap;
}
.mind-panel__column {
	display: flex;
	align-items: center;
	margin: 5px 10px 5px 0;
}

.mind-panel__label {
	display: block;
    margin:0 15px 0  0;
    color: #30547F;
    font-weight: bold;
}
.mind-panel__button-small {
	padding: 0px 0px;
	min-width:40px;
}

.mind-panel__input {
	box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.2);
    border-radius: 26px;
    width: 100%;
    height: 40px;
    padding: 0px 15px;
    align-items: center;
    justify-content: space-between;
	 border:none;
	 max-width:20px;
	 text-align: center;
	 flex:0 0 40px;
	 margin: 0 5px;
	 
}
.mind-panel__button {
}


.grid {
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(1, minmax(auto, 100px));
  grid-template-rows: repeat(1, minmax(auto, 100px));
  background-color: #ffffff;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.2);
  border-radius: 26px;
  justify-content: center;
  align-items: center;
  max-height: 100vh;
  overflow: auto; /* Добавьте эту строку */
}

/* Добавьте этот стиль для элементов внутри грида */
.mind-images__item {
  max-width: 100%; /* Измените на max-width */
  max-height: 100%; /* Измените на max-height */
}

.item-mind-images__image {
  width: 100%;
  padding-bottom: 100%;
  background-color: #fff;
}

/* Добавьте этот стиль для изображений внутри элемента mind-images__item */
.item-mind-images__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>